<template>
    <v-layout column="" row wrap pr-3 mr-2 mt-1>

        <v-row>

            <v-data-table :headers="headers" :items="subscriptions" sort-by="calories" class="elevation-1 request_table"
                style="width:96%" :items-per-page="25" hide-default-footer="" :loading="loading"
                loading-text="جاري تحميل البيانات">


                <template v-slot:top>
                    <v-toolbar flat color="white" pb-5>
                        <v-toolbar-title>
                            طلبات شراء حزم اشتراك
                        </v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>


                        <v-spacer></v-spacer>
                      

                    </v-toolbar>
                </template>


                <template v-slot:item.billState="{ item }">


                    <div v-if="item.is_free==0">

                        <div v-if="item.bill.tasdid_bills !==null">
                            <v-chip style="color:#fff" color="green"
                                v-if="item.bill.tasdid_bills.Status==3 && item.bill.status_id==31" class="ma-2">
                                تم التسديد
                            </v-chip>

                            <v-chip style="color:#fff" color="red" v-else class="ma-2">
                                لم يتم التسديد
                            </v-chip>
                        </div>
                        <div v-else>
                            <v-chip style="color:#fff" color="red" class="ma-2">
                                لم يتم التسديد
                            </v-chip>
                        </div>






                    </div>

                    <div v-else>
                        <v-chip style="color:#fff" color="blue" class="ma-2">
                            حزمه مجانيه
                        </v-chip>

                    </div>

                </template>


                <template v-slot:item.State="{ item }">

                    <v-chip dark="" :color="item.status.status_color">
                        <span style="color:#fff"> {{item.status.status_name_ar}}
                            <v-icon left size="12"> {{item.status.status_icon}}</v-icon>
                        </span>

                    </v-chip>


                </template>




                <!-- <template v-slot:item.edit="{ item }">

                   
                </template>

 -->


                <template v-slot:item.edit="{ item }">


                    <v-row wrap justify="center">
                        <v-btn color="green" @click="changeState(29,item.id)" style="color:#fff"
                            v-if="item.status.id==28">
                            تفعيل

                        </v-btn>

                        <v-btn color="green" @click="changeState(28,item.id)" style="color:#fff"
                            v-else-if="item.status.id==29">
                            ايقاف

                        </v-btn>

                    </v-row>


                </template>
                <v-radio-group required mandatory v-model="editedItem.subscriptions_id">
                    <v-layout row wrap pa-4>
                        <v-flex pt-2 xs12 v-for="item in  subscriptions" :key="item.id">
                            <v-card style="padding:10px;width:100%">

                                <v-radio
                                    :label=" item.package_name+' | عدد الحجوزات  '+item.reservations_count +'| السعر '+item.price_value+'دينار'"
                                    :value="item.id">


                                    <template v-slot:label>

                                        <v-layout row wrap>
                                            <v-flex xs2 md2 sm2 pr-5>
                                                <div style="text-align:right;"> <strong
                                                        style="color:green">{{item.package_name}} </strong> </div>
                                            </v-flex>

                                            <v-flex pr-3 xs5 md3 sm3>
                                                <div><span style="font-weight:bold">عدد الحجوزات
                                                    </span> <strong style="color:blue;padding-right:10px">
                                                        {{item.reservations_count}} </strong> </div>
                                            </v-flex>

                                            <v-flex xs1 md3 sm3>

                                            </v-flex>

                                            <v-flex xs4 sm3 md3>
                                                <div><strong style="color:red;padding-right:10px;">السعر </strong>
                                                    {{item.price_value}} دينار</div>

                                            </v-flex>

                                        </v-layout>



                                    </template>
                                </v-radio>
                            </v-card>
                        </v-flex>
                    </v-layout>
                </v-radio-group>


            </v-data-table>

        </v-row>
        <v-row>


            <v-pagination v-model="page" prev-icon="mdi-menu-right" next-icon="mdi-menu-left" circle=""
                :length="pageCount">
            </v-pagination>
        </v-row>


    </v-layout>

</template>


<script>
    export default {
        data: () => ({
            subscriptions: [],
            validate: false,
            page: 1,
            pageCount: 0,
            current_page: 1,
            last_page: 0,
            editedIndex: -1,
            calander_dialog: false,
            err_show: false,
            dialog: false,



            loding_accept: false,
            loding_cancel: false,
            headers: [{
                    text: '#',
                    align: 'center',
                    sortable: false,
                    value: 'id',
                },
                {
                    text: 'اسم التاجر',
                    align: 'center',
                    sortable: false,
                    value: 'owner.user.full_name',
                },

                {
                    text: 'رقم الهاتف',
                    align: 'center',
                    sortable: false,
                    value: 'owner.user.user_phone',
                },

                {
                    text: 'حزمه الاشراك',
                    align: 'center',
                    sortable: false,
                    value: 'subscription_package.package_name',
                },

                {
                    text: 'حاله الفاتوره',
                    value: 'billState',
                    sortable: false
                },

                {
                    text: 'حاله الاشتراك',
                    value: 'State',
                    sortable: false
                },



                {
                    text: '',
                    value: 'edit',
                    sortable: false
                },


            ],



            loading: false,
            editedItem: {
                id: '',
                package_name: '',
                reservations_count: '',
                price_value: '',
                subscriptions_id: ''
            },

            valid: false,




        }),



        methods: {

            changeState(state_id, OwnerSubscriptionId) {

                var data = {
                    state_id: state_id,
                    OwnerSubscriptionId: OwnerSubscriptionId,

                };
                var posturl = '/v2/OwnerSubscriptions/operation/changeStatue';
                var alertTitle = '';
                if (state_id == 29) {
                    alertTitle = 'تفعيل الحزمه';

                } else if (state_id == 28) {
                    alertTitle = 'ايقاف تفعيل الحزمه';

                }



                const Swal = require('sweetalert2')


                Swal.fire({
                    title: " هل انت متاكد من " + alertTitle,

                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'نعم',
                    cancelButtonText: 'خروج'

                }).then((result) => {

                    if (result.value) {
                        this.$http({
                                method: 'post',
                                url: posturl,
                                data: data,
                                headers: {

                                }

                            })
                            .then(response => {


                                if (state_id == 29) {
                                    this.$swal("تم التفعيل ", "", "success")

                                } else {
                                    this.$swal("تم الايقاف", "", "success")


                                }

                                this.getItems();

                                if (response.data.success == true) {
                                    this.$refs.form.reset()
                                    this.addlocation = false;


                                }
                                //



                            })
                            .catch(error => {
                                error
                            }).finally(d => {
                                d
                            });
                    }
                });
            },



            editItem(item) {
                this.dialog = true;

                this.editedItem = Object.assign({}, item)
                this.editedIndex = this.subscriptions.indexOf(item)



            },

            getItems() {
                this.loading = true;





                this.loading = true;
                this.$http({
                    method: 'get',
                    url: '/v2/OwnerSubscriptions/operation/get',
                    headers: {

                    }

                }).then(response => {
                    this.loading = false;
                    this.subscriptions = response.data.data;
                    this.last_page = response.data.data.last_page;
                    this.pageCount = response.data.data.last_page;

                }).catch(error => {
                    error
                    //this.$swal('خطاء', "خطاء بالاتصال", 'error')
                }).finally(s => {
                    s,
                    this.loading = false;
                });
            },







            close() {
                this.dialog = false;
                this.getItems();
            },






            getMoreitems() {

                if (this.current_page <= this.last_page) {
                    this.current_page = this.page;
                    this.getItems();
                }



            },





        },

        mounted() {
            this.getItems();
        },


        computed: {

            selected: function () {

                return this.getMoreitems();
            }


        },


        watch: {
            selected: 'search by sub_cat_id',
        },

    }
</script>